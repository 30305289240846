<template lang="pug">
  .fb
    .fb__container.container

      //- Титульная линия страницы
      .fb__top-line
        .fb__title-container.fb__title-container--row
          h1.fb__title {{ title }}
          el-tooltip(
            content="Дополнительная информация"
            placement="right"
            effect="light"
          )
            button.fb__tooltip.btn.btn--tooltip
              svg(width="16" height="16")
                use(xlink:href="@/assets/images/sprite/sprite.svg#icon-info")
        button.fb__btn.btn.btn--transparent(@click="$router.push({ name: 'advanced-search' })") Расширенный поиск
      
      //- Поиск
      .fb__body

        //- Поиск объекта
        .search.search--flex
          el-autocomplete(
            v-model="searchText"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            :debounce="700"
            value-key="full_name"
            placeholder="Введите адрес"
            @select="handleSelectObject"
            class="custom-el"
          )
          button.search__append-btn.btn.btn--main Найти

        .fb__content(
          :class="{ 'fb__content--min-height': showPreload }"
        )
          a-preload(
            v-show="showPreload"
          )
          //- Отображение найденного объекта
          Object(
            v-if="object"
            :object="object"
            @changeParams="changeParams"
          )
</template>

<script>
import AFormEl from '../../../../components/a-form-el/a-form-el'
import Object from '../components/Object'
import APreload from '../../../../components/a-preload/a-preload'

export default {
  name: "DefaultSearch",

  components: {
    AFormEl,
    Object,
    APreload
  },

  data() {
    return {
      title: "Поиск объекта",
      searchText: '',
      objectid: null,
      showPreload: false
    }
  },
  computed: {
    searchList() {
      return this.$store.getters["search/searchList"];
    },
    object() {
      return this.$store.getters["search/object"];
    }
  },
  
  methods: {
    changeValue(field, value) {
      this.formFields[field].value = value
      this.getSearchList()
    },
    handleSelectObject(item) {
      this.objectid = item.id
      this.getObject(this.objectid)
      console.log(item);
    },
    querySearch(queryString, cb) {
      cb([]);
      this.$store.dispatch("search/getSearchList", queryString).then((data) => {
        (data.length === 0) ? cb([]) : cb(data);
      })
    },
    async getSearchList() {
      try {
        await this.$store.dispatch("search/getSearchList", this.formFields.search.value);
        this.formFields.search.options = this.searchList
      } catch (error) {
        console.log(error);
      }
    },
    async getObject(id) {
      try {
        this.showPreload = true
        await this.$store.dispatch("search/getObject", id)
        this.showPreload = false
      } catch (error) {
        console.log(error);
      }
    },

    async changeParams(postalCode, cadastralNumber) {
      const data_ = {}
      if (postalCode) {
        data_['pochta_index'] = postalCode
      }
      if (cadastralNumber) {
        data_['kadastr'] = cadastralNumber
      }
      if (this.objectid) {
        data_['objectid'] = this.objectid
      }
      try {
        this.showPreload = true
        await this.$store.dispatch("changeHouseParams/changeHouseParams", data_)
        await this.getObject(this.objectid)
        this.showPreload = false
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>