<template lang="pug">
  .preload(
    :class="{'preload--table' : table}"
  )
    .preload__element Загрузка
</template>

<script>
export default {
  name: "a-preload",
  props: {
    table: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
