<template lang="pug">
  .download-registry
    h2.download-registry__title {{ title }}
    table.download-registry__table
      tr.download-registry__table-headers
        th.download-registry__table-header(v-for="(item, $index) in headers" :key="$index") {{ item }}
      tr(v-for="(item, $index) in registry" :key="$index").download-registry__table-row
        td.download-registry__table-column {{ item.id }}
        td.download-registry__table-column {{ item.date }}
        td.download-registry__table-column {{ item.size }}
        td.download-registry__table-column {{ item.update }}
        td.download-registry__table-column {{ item.executor }}
</template>

<script>
export default {
  name: 'DownloadRegistry',

  props: {
    registry: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      title: 'Реестр загрузок ГАР БД ФИАС КО',
      headers: [
        'ID загрузки',
        'Дата размещения',
        'Размер файла',
        'Обновлено',
        'Исполнитель',
      ]
    }
  }
}
</script>