<template lang="pug">
  .object
    h2.object__title {{ object.mun_div }}

    table.object__table

      tr(
        v-for="(row, $index) in table"
        :key="$index"
      )
        td.object__table-column-1
          .object__name-cell
            p.object__name {{ row.label }}
            el-tooltip(
              :content="row.label"
              placement="right"
              effect="light"
            )
              button.object__tooltip.btn.btn--tooltip
                svg(width="16" height="16")
                  use(xlink:href="@/assets/images/sprite/sprite.svg#icon-info")
        td.object__table-column-2
          p.object__value(
            v-if="!showEditor || !row.edit"
          ) {{ object[row.prop] || '---------------' }}
          input.object__input(
            v-if="showEditor && row.edit"
            v-model="row.newValue"
            :placeholder="object[row.prop] || '...'"
          )
      //--------------------------------------------

    .object__btn-group
      .object__editor-btns(
        v-if="showEditor"
      )
        button.object__btn.object__btn--medium.btn.btn--main.btn--alt(
          @click="showEditor = false"
        ) Назад
        button.object__btn.object__btn--medium.btn.btn--main(
          @click="showModal = true"
        ) Сохранить
      .object__control-btns
        button.object__btn-icon.btn.btn--icon(@click="showHistory = !showHistory")
          svg(width="24" height="24")
            use(xlink:href="@/assets/images/sprite/sprite.svg#icon-history")
        button.object__btn-icon.btn.btn--icon(
          v-if="isLogin"
          @click="showEditor = !showEditor"
        )
          svg(width="24" height="24")
            use(xlink:href="@/assets/images/sprite/sprite.svg#icon-editor")
    //- Разворачиваемый список (element-ui)
    el-collapse-transition
      div(v-show="showHistory")

        //- Реестр загрузок
        DownloadRegistry(
          :registry="registry"
        )

    //- Модальные окна
    el-dialog(
      title="Подтвердите изменение записи"
      :visible.sync="showModal"
      width="450px"
    )
      .modal
        .modal__btns-group
          button.modal__btn.btn.btn--main.btn--alt(
            @click="showModal = false"
          ) Отмена
          button.modal__btn.btn.btn--main(
            @click="changeParams"
          ) Подтвердить
</template>

<script>
import DownloadRegistry from '../components/DownloadRegistry'

export default {
  name: "Object",

  components: {
    DownloadRegistry
  },

  props: {
    object: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      showHistory: false,
      showEditor: false,
      showModal: false,
      table: [
        { label: 'Мунициальное деление', prop: 'mun_div' },
        { label: 'ИФНС ФЛ', prop: 'ifns_fl' },
        { label: 'ИФНС ЮЛ', prop: 'ifns_ul' },
        { label: 'Почтовый индекс', prop: 'postal_index', edit: true, newValue: '' },
        { label: 'Уникальный номер адреса объекта в государственном адресном реестре', prop: 'objectid' },
        { label: 'Уникальный номер реестровой записи', prop: 'reestr_id' },
        { label: 'Кадастровый номер', prop: 'cad_num', edit: true, newValue: '' },
        { label: 'Статус записи', prop: 'status' },
        { label: 'Дата внесения записи', prop: 'startdate' },
        { label: 'Дата актуализации записи', prop: 'updatedate' },
        { label: 'Местоположение', prop: 'location' },
        { label: 'Нормативный документ', prop: 'ndoc' }
      ],
      //- Реестр загрузок
      registry: [
        {
          id: '2570',
          date: '07.09.2021',
          size: '235 Mb',
          update: '18.09.2021',
          executor: 'Мешков М.Н.'
        },
        {
          id: '2570',
          date: '07.09.2021',
          size: '235 Mb',
          update: '18.09.2021',
          executor: 'Мешков М.Н.'
        },
        {
          id: '2570',
          date: '07.09.2021',
          size: '235 Mb',
          update: '18.09.2021',
          executor: 'Мешков М.Н.'
        },
        {
          id: '2570',
          date: '07.09.2021',
          size: '235 Mb',
          update: '18.09.2021',
          executor: 'Мешков М.Н.'
        },
        {
          id: '2570',
          date: '07.09.2021',
          size: '235 Mb',
          update: '18.09.2021',
          executor: 'Мешков М.Н.'
        },
        {
          id: '2570',
          date: '07.09.2021',
          size: '235 Mb',
          update: '18.09.2021',
          executor: 'Мешков М.Н.'
        }
      ],
    }
  },

  computed: {
    isLogin() {
      return this.$store.getters['auth/isAuthenticated']
    },
    newPostalCode() {
      return this.table.filter(item => item.label === 'Почтовый индекс')[0].newValue
    },
    newCadastralNumber() {
      return this.table.filter(item => item.label === 'Кадастровый номер')[0].newValue
    }
  },

  methods: {
    changeParams() {
      this.showModal = false
      this.showEditor = false
      this.$emit('changeParams', this.newPostalCode, this.newCadastralNumber)
      this.table.forEach(item => {
        if (item.edit) {
          item.newValue = ''
        }
      })
    }
  }
}
</script>